function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
// typed-ember doesn't have types for `@ember/helper` yet
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { capabilities as helperCapabilities, setHelperManager } from '@ember/helper';
export function initialize( /* appInstance */
) {
  // noop.
  // we just want the host app to import this file
  // (because the default manager behavior is in glimmer-vm,
  //  and I don't know how to monkey-patch ESM... (I don't think I'd want to either? (danger!)))
}
export default {
  initialize
};
export class FunctionHelperManager {
  constructor() {
    _defineProperty(this, "capabilities", helperCapabilities('3.23', {
      hasValue: true,
      hasDestroyable: false,
      hasScheduledEffect: false
    }));
  }
  createHelper(fn, args) {
    return {
      fn,
      args
    };
  }
  getValue({
    fn,
    args
  }) {
    if (Object.keys(args.named).length > 0) {
      let argsForFn = [...args.positional, args.named];
      return fn(...argsForFn);
    }
    return fn(...args.positional);
  }
  getDebugName(fn) {
    if (fn.name) {
      return `(helper function ${fn.name})`;
    }
    return '(anonymous helper function)';
  }
}
const FUNCTIONAL_HELPER_MANAGER = new FunctionHelperManager();
setHelperManager(() => FUNCTIONAL_HELPER_MANAGER, Function.prototype);