var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { cancel, next } from "@ember/runloop";
import { makeArray } from "discourse-common/lib/helpers";
import discourseLater from "discourse-common/lib/later";
import { bind } from "discourse-common/utils/decorators";
const TOUCH_OPTIONS = {
  passive: true,
  capture: true
};
function cancelEvent(event) {
  event.preventDefault();
  event.stopImmediatePropagation();
}
let FloatKitInstance = (_class = class FloatKitInstance {
  constructor() {
    _initializerDefineProperty(this, "expanded", _descriptor, this);
    _initializerDefineProperty(this, "id", _descriptor2, this);
    _defineProperty(this, "trigger", null);
    _defineProperty(this, "content", null);
  }
  async show() {
    this.expanded = true;
    await new Promise(resolve => next(resolve));
  }
  async close() {
    this.expanded = false;
    await new Promise(resolve => next(resolve));
  }
  onFocus(event) {
    this.onTrigger(event);
  }
  onBlur(event) {
    this.onTrigger(event);
  }
  onFocusIn(event) {
    this.onTrigger(event);
  }
  onFocusOut(event) {
    this.onTrigger(event);
  }
  onTouchStart(event) {
    if (event.touches.length > 1) {
      this.onTouchCancel();
      return;
    }
    event.stopPropagation();
    this.trigger.addEventListener("touchmove", this.onTouchCancel, TOUCH_OPTIONS);
    this.trigger.addEventListener("touchcancel", this.onTouchCancel, TOUCH_OPTIONS);
    this.trigger.addEventListener("touchend", this.onTouchCancel, TOUCH_OPTIONS);
    this.touchTimeout = discourseLater(() => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.trigger.addEventListener("touchend", cancelEvent, {
        once: true,
        capture: true
      });
      this.onTrigger(event);
    }, 500);
  }
  onTouchCancel() {
    cancel(this.touchTimeout);
    this.trigger.removeEventListener("touchmove", this.onTouchCancel);
    this.trigger.removeEventListener("touchend", this.onTouchCancel);
    this.trigger.removeEventListener("touchcancel", this.onTouchCancel);
  }
  tearDownListeners() {
    if (!this.options.listeners) {
      return;
    }
    makeArray(this.triggers).filter(Boolean).forEach(trigger => {
      switch (trigger) {
        case "hold":
          this.trigger.addEventListener("touchstart", this.onTouchStart);
          break;
        case "focus":
          this.trigger.removeEventListener("focus", this.onFocus);
          this.trigger.removeEventListener("blur", this.onBlur);
          break;
        case "focusin":
          this.trigger.removeEventListener("focusin", this.onFocusIn);
          this.trigger.removeEventListener("focusout", this.onFocusOut);
          break;
        case "hover":
          this.trigger.removeEventListener("mousemove", this.onMouseMove);
          if (!this.options.interactive) {
            this.trigger.removeEventListener("mouseleave", this.onMouseLeave);
          }
          break;
        case "click":
          this.trigger.removeEventListener("click", this.onClick);
          break;
      }
    });
    cancel(this.touchTimeout);
  }
  setupListeners() {
    if (!this.options.listeners) {
      return;
    }
    makeArray(this.triggers).filter(Boolean).forEach(trigger => {
      switch (trigger) {
        case "hold":
          this.trigger.addEventListener("touchstart", this.onTouchStart, TOUCH_OPTIONS);
          break;
        case "focus":
          this.trigger.addEventListener("focus", this.onFocus, {
            passive: true
          });
          this.trigger.addEventListener("blur", this.onBlur, {
            passive: true
          });
          break;
        case "focusin":
          this.trigger.addEventListener("focusin", this.onFocusIn, {
            passive: true
          });
          this.trigger.addEventListener("focusout", this.onFocusOut, {
            passive: true
          });
          break;
        case "hover":
          this.trigger.addEventListener("mousemove", this.onMouseMove, {
            passive: true
          });
          if (!this.options.interactive) {
            this.trigger.addEventListener("mouseleave", this.onMouseLeave, {
              passive: true
            });
          }
          break;
        case "click":
          this.trigger.addEventListener("click", this.onClick, {
            passive: true
          });
          break;
      }
    });
  }
  get triggers() {
    return this.options.triggers ?? ["click"];
  }
  get untriggers() {
    return this.options.untriggers ?? ["click"];
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "expanded", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "id", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class.prototype, "show", [action], Object.getOwnPropertyDescriptor(_class.prototype, "show"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocus", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBlur", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onBlur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocusIn", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocusOut", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTouchStart", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTouchCancel", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "onTouchCancel"), _class.prototype)), _class);
export { FloatKitInstance as default };