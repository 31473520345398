import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
const DButtonTooltip = setComponentTemplate(precompileTemplate(`
  <div class="fk-d-button-tooltip" ...attributes>
    {{yield to="button"}}
    {{yield to="tooltip"}}
  </div>
`, {
  strictMode: true
}), templateOnly("d-button-tooltip", "DButtonTooltip"));
export default DButtonTooltip;