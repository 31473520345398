var _class, _descriptor;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { registerDestructor } from "@ember/destroyable";
import { inject as service } from "@ember/service";
import Modifier from "ember-modifier";
import { bind } from "discourse-common/utils/decorators";
let FloatKitCloseOnEscape = (_class = class FloatKitCloseOnEscape extends Modifier {
  constructor(owner, args) {
    super(owner, args);
    _initializerDefineProperty(this, "menu", _descriptor, this);
    registerDestructor(this, instance => instance.cleanup());
  }
  modify(element, [closeFn]) {
    this.closeFn = closeFn;
    this.element = element;
    document.addEventListener("keydown", this.check);
  }
  check(event) {
    if (event.key === "Escape") {
      event.stopPropagation();
      event.preventDefault();
      this.closeFn();
    }
  }
  cleanup() {
    document.removeEventListener("keydown", this.check);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "menu", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "check", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "check"), _class.prototype)), _class);
export { FloatKitCloseOnEscape as default };