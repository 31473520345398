import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import DFloatBody from "float-kit/components/d-float-body";
const DInlineFloat = setComponentTemplate(precompileTemplate(`
  {{#if @instance.expanded}}
    <DFloatBody
      @instance={{@instance}}
      @trapTab={{@trapTab}}
      @mainClass={{@mainClass}}
      @innerClass={{@innerClass}}
      @role={{@role}}
      @portalOutletElement={{@portalOutletElement}}
      @inline={{@inline}}
    >
      {{#if @instance.options.component}}
        <@instance.options.component
          @data={{@instance.options.data}}
          @close={{@instance.close}}
        />
      {{else}}
        {{@instance.options.content}}
      {{/if}}
    </DFloatBody>
  {{/if}}
`, {
  strictMode: true,
  scope: () => ({
    DFloatBody
  })
}), templateOnly("d-inline-float", "DInlineFloat"));
export default DInlineFloat;