// DO NOT EDIT THIS FILE!!!
// Update it by running `rake javascript:update_constants`

export const SEARCH_PRIORITIES = {
  ignore: 1,
  very_low: 2,
  low: 3,
  normal: 0,
  high: 4,
  very_high: 5,
};

export const SEARCH_PHRASE_REGEXP = '"([^"]+)"';

export const SIDEBAR_URL = {
  max_icon_length: 40,
  max_name_length: 80,
  max_value_length: 1000,
};

export const SIDEBAR_SECTION = {
  max_title_length: 30,
};
