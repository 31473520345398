import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from "@glimmer/component";
import { isTesting } from "discourse-common/config/environment";
export default class DFloatPortal extends Component {
  get inline() {
    return this.args.inline ?? isTesting();
  }
}
setComponentTemplate(precompileTemplate(`
    {{#if this.inline}}
      {{yield}}
    {{else}}
      {{#in-element @portalOutletElement}}
        {{yield}}
      {{/in-element}}
    {{/if}}
  `, {
  strictMode: true
}), DFloatPortal);