var _dec, _obj;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import { htmlSafe } from "@ember/template";
import { categoryBadgeHTML } from "discourse/helpers/category-link";
import discourseComputed from "discourse-common/utils/decorators";
import CategoryRowComponent from "select-kit/components/category-row";
export default CategoryRowComponent.extend((_dec = discourseComputed("category"), (_obj = {
  classNames: "none category-row",
  badgeForCategory(category) {
    return htmlSafe(categoryBadgeHTML(category, {
      link: this.categoryLink,
      allowUncategorized: true,
      hideParent: true
    }));
  }
}, (_applyDecoratedDescriptor(_obj, "badgeForCategory", [_dec], Object.getOwnPropertyDescriptor(_obj, "badgeForCategory"), _obj)), _obj)));