var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { getOwner } from "@ember/application";
import { inject as service } from "@ember/service";
import { modifier } from "ember-modifier";
import DButton from "discourse/components/d-button";
import concatClass from "discourse/helpers/concat-class";
import DFloatBody from "float-kit/components/d-float-body";
import DMenuInstance from "float-kit/lib/d-menu-instance";
let DMenu = setComponentTemplate(precompileTemplate(`
    {{! template-lint-disable modifier-name-case }}
    <DButton
      class={{concatClass
        "fk-d-menu__trigger"
        (if this.menuInstance.expanded "-expanded")
      }}
      id={{this.menuInstance.id}}
      data-identifier={{this.options.identifier}}
      data-trigger
      @icon={{@icon}}
      @translatedAriaLabel={{@ariaLabel}}
      @translatedLabel={{@label}}
      @translatedTitle={{@title}}
      @disabled={{@disabled}}
      aria-expanded={{if this.menuInstance.expanded "true" "false"}}
      {{this.registerTrigger}}
      ...attributes
    >
      {{#if (has-block "trigger")}}
        {{yield this.componentArgs to="trigger"}}
      {{/if}}
    </DButton>

    {{#if this.menuInstance.expanded}}
      <DFloatBody
        @instance={{this.menuInstance}}
        @trapTab={{this.options.trapTab}}
        @mainClass="fk-d-menu"
        @innerClass="fk-d-menu__inner-content"
        @role="dialog"
        @inline={{this.options.inline}}
        @portalOutletElement={{this.menu.portalOutletElement}}
      >
        {{#if (has-block)}}
          {{yield this.componentArgs}}
        {{else if (has-block "content")}}
          {{yield this.componentArgs to="content"}}
        {{else if this.options.component}}
          <this.options.component
            @data={{this.options.data}}
            @close={{this.menuInstance.close}}
          />
        {{else if this.options.content}}
          {{this.options.content}}
        {{/if}}
      </DFloatBody>
    {{/if}}
  `, {
  strictMode: true,
  scope: () => ({
    DButton,
    concatClass,
    DFloatBody
  })
}), (_class = class DMenu extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "menu", _descriptor, this);
    _initializerDefineProperty(this, "menuInstance", _descriptor2, this);
    _defineProperty(this, "registerTrigger", modifier(element => {
      const options = {
        ...this.args,
        ...{
          autoUpdate: true,
          listeners: true,
          beforeTrigger: () => {
            this.menu.close();
          }
        }
      };
      const instance = new DMenuInstance(getOwner(this), element, options);
      this.menuInstance = instance;
      return () => {
        instance.destroy();
        if (this.isDestroying) {
          this.menuInstance = null;
        }
      };
    }));
  }
  get menuId() {
    return `d-menu-${this.menuInstance.id}`;
  }
  get options() {
    return this.menuInstance?.options ?? {};
  }
  get componentArgs() {
    return {
      close: this.menuInstance.close,
      data: this.options.data
    };
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "menu", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "menuInstance", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
})), _class));
export { DMenu as default };