// DO NOT EDIT THIS FILE!!!
// Update it by running `rake javascript:update_constants`

export const HLJS_ALIASES = {
  bash: ["sh"],
  c: ["h"],
  cpp: ["cc", "c++", "h++", "hpp", "hh", "hxx", "cxx"],
  csharp: ["cs", "c#"],
  diff: ["patch"],
  go: ["golang"],
  graphql: ["gql"],
  ini: ["toml"],
  java: ["jsp"],
  javascript: ["js", "jsx", "mjs", "cjs"],
  kotlin: ["kt", "kts"],
  makefile: ["mk", "mak", "make"],
  xml: ["html", "xhtml", "rss", "atom", "xjb", "xsd", "xsl", "plist", "wsf", "svg"],
  markdown: ["md", "mkdown", "mkd"],
  objectivec: ["mm", "objc", "obj-c", "obj-c++", "objective-c++"],
  perl: ["pl", "pm"],
  plaintext: ["text", "txt"],
  python: ["py", "gyp", "ipython"],
  "python-repl": ["pycon"],
  ruby: ["rb", "gemspec", "podspec", "thor", "irb"],
  rust: ["rs"],
  shell: ["console", "shellsession"],
  typescript: ["ts", "tsx"],
  vbnet: ["vb"],
  yaml: ["yml"],
  actionscript: ["as"],
  angelscript: ["asc"],
  apache: ["apacheconf"],
  applescript: ["osascript"],
  arduino: ["ino"],
  armasm: ["arm"],
  asciidoc: ["adoc"],
  autohotkey: ["ahk"],
  axapta: ["x++"],
  brainfuck: ["bf"],
  "c-like": [],
  capnproto: ["capnp"],
  clean: ["icl", "dcl"],
  clojure: ["clj", "edn"],
  cmake: ["cmake.in"],
  coffeescript: ["coffee", "cson", "iced"],
  cos: ["cls"],
  crmsh: ["crm", "pcmk"],
  crystal: ["cr"],
  delphi: ["dpr", "dfm", "pas", "pascal"],
  django: ["jinja"],
  dns: ["bind", "zone"],
  dockerfile: ["docker"],
  dos: ["bat", "cmd"],
  dust: ["dst"],
  elixir: ["ex", "exs"],
  erlang: ["erl"],
  excel: ["xlsx", "xls"],
  fortran: ["f90", "f95"],
  fsharp: ["fs", "f#"],
  gams: ["gms"],
  gauss: ["gss"],
  gcode: ["nc"],
  gherkin: ["feature"],
  handlebars: ["hbs", "html.hbs", "html.handlebars", "htmlbars"],
  haskell: ["hs"],
  haxe: ["hx"],
  htmlbars: ["hbs", "html.hbs", "html.handlebars", "htmlbars"],
  http: ["https"],
  hy: ["hylang"],
  inform7: ["i7"],
  "jboss-cli": ["wildfly-cli"],
  "julia-repl": ["jldoctest"],
  lasso: ["ls", "lassoscript"],
  latex: ["tex"],
  livescript: ["ls"],
  mathematica: ["mma", "wl"],
  mercury: ["m", "moo"],
  mipsasm: ["mips"],
  moonscript: ["moon"],
  nestedtext: ["nt"],
  nginx: ["nginxconf"],
  nimrod: ["nim"],
  nix: ["nixos"],
  ocaml: ["ml"],
  openscad: ["scad"],
  pf: ["pf.conf"],
  pgsql: ["postgres", "postgresql"],
  powershell: ["pwsh", "ps", "ps1"],
  processing: ["pde"],
  puppet: ["pp"],
  purebasic: ["pb", "pbi"],
  q: ["k", "kdb"],
  qml: ["qt"],
  reasonml: ["re"],
  roboconf: ["graph", "instances"],
  routeros: ["mikrotik"],
  scilab: ["sci"],
  smalltalk: ["st"],
  sml: ["ml"],
  sql_more: ["mysql", "oracle"],
  stan: ["stanfuncs"],
  stata: ["do", "ado"],
  step21: ["p21", "step", "stp"],
  stylus: ["styl"],
  tcl: ["tk"],
  twig: ["craftcms"],
  vbscript: ["vbs"],
  verilog: ["v", "sv", "svh"],
  xl: ["tao"],
  xquery: ["xpath", "xq"],
  zephir: ["zep"]
};