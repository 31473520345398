var _class, _descriptor;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import { modifier } from "ember-modifier";
import deprecated from "discourse-common/lib/deprecated";
let DPopover = setComponentTemplate(precompileTemplate(`
    {{! template-lint-disable modifier-name-case }}
    <div style="display:inline-flex;" {{this.registerDTooltip}}>
      {{yield}}
    </div>
  `, {
  strictMode: true
}), (_class = class DPopover extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "tooltip", _descriptor, this);
    _defineProperty(this, "registerDTooltip", modifier(element => {
      deprecated("`<DPopover />` is deprecated. Use `<DTooltip />` or the `tooltip` service instead.", {
        id: "discourse.d-popover"
      });
      const trigger = element.children[0];
      const content = element.children[1];
      if (!trigger || !content) {
        return;
      }
      const instance = this.tooltip.register(trigger, {
        content
      });
      content.remove();
      return () => {
        instance.destroy();
      };
    }));
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tooltip", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));
export { DPopover as default };