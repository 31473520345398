var _class, _descriptor;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { tracked } from "@glimmer/tracking";
import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import Service from "@ember/service";
import { TrackedArray } from "@ember-compat/tracked-built-ins";
import DDefaultToast from "float-kit/components/d-default-toast";
import DToastInstance from "float-kit/lib/d-toast-instance";
let Toasts = (_class = class Toasts extends Service {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "activeToasts", _descriptor, this);
  }
  /**
   * Render a toast
   *
   * @param {Object} [options] - options passed to the toast component as `@toast` argument
   * @param {String} [options.duration] - The duration (ms) of the toast, will be closed after this time
   * @param {ComponentClass} [options.component] - A component to render, will use `DDefaultToast` if not provided
   * @param {String} [options.class] - A class added to the d-toast element
   * @param {Object} [options.data] - An object which will be passed as the `@data` argument to the component
   *
   * @returns {DToastInstance} - a toast instance
   */
  show(options = {}) {
    const instance = new DToastInstance(getOwner(this), options);
    this.activeToasts.push(instance);
    return instance;
  }

  /**
   * Render a DDefaultToast toast with the default theme
   *
   * @param {Object} [options] - @see show
   *
   * @returns {DToastInstance} - a toast instance
   */
  default(options = {}) {
    options.data.theme = "default";
    return this.show({
      ...options,
      component: DDefaultToast
    });
  }

  /**
   * Render a DDefaultToast toast with the success theme
   *
   * @param {Object} [options] - @see show
   *
   * @returns {DToastInstance} - a toast instance
   */
  success(options = {}) {
    options.data.theme = "success";
    options.data.icon = "check";
    return this.show({
      ...options,
      component: DDefaultToast
    });
  }

  /**
   * Render a DDefaultToast toast with the error theme
   *
   * @param {Object} [options] - @see show
   *
   * @returns {DToastInstance} - a toast instance
   */
  error(options = {}) {
    options.data.theme = "error";
    options.data.icon = "exclamation-triangle";
    return this.show({
      ...options,
      component: DDefaultToast
    });
  }

  /**
   * Render a DDefaultToast toast with the warning theme
   *
   * @param {Object} [options] - @see show
   *
   * @returns {DToastInstance} - a toast instance
   */
  warning(options = {}) {
    options.data.theme = "warning";
    options.data.icon = "exclamation-circle";
    return this.show({
      ...options,
      component: DDefaultToast
    });
  }

  /**
   * Render a DDefaultToast toast with the info theme
   *
   * @param {Object} [options] - @see show
   *
   * @returns {DToastInstance} - a toast instance
   */
  info(options = {}) {
    options.data.theme = "info";
    options.data.icon = "info-circle";
    return this.show({
      ...options,
      component: DDefaultToast
    });
  }

  /**
   * Close a toast. Any object containg a valid `id` property can be used as a toast parameter.
   */
  close(toast) {
    this.activeToasts = new TrackedArray(this.activeToasts.filter(activeToast => activeToast.id !== toast.id));
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "activeToasts", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return new TrackedArray();
  }
}), _applyDecoratedDescriptor(_class.prototype, "show", [action], Object.getOwnPropertyDescriptor(_class.prototype, "show"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "default", [action], Object.getOwnPropertyDescriptor(_class.prototype, "default"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "success", [action], Object.getOwnPropertyDescriptor(_class.prototype, "success"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [action], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "warning", [action], Object.getOwnPropertyDescriptor(_class.prototype, "warning"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "info", [action], Object.getOwnPropertyDescriptor(_class.prototype, "info"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class);
export { Toasts as default };